/* TODO:  
*/
@use 'src/Sass/global' as *;

// COMPONENT CENTERED ON MIDDLE COLUMN OF MAIN LAYOUT GRID
// THEN ORGANIZED INTO VERTICAL GRID
//!!!!!! DO NOT USE FR UNITS FOR CAROUSEL

// VARIABLES
$clamp-1: 8rem;
$clamp-2: 30vw;
$clamp-3: 9rem;
$body-perc: 0.7;
$body-corner-padding-perc: (0.17 * $body-perc) + 0.02;
$body-font-perc: (0.17 * $body-perc) + 0.02;
$bkd-width: 50%;
$project-spacing-grid: 1rem;

// COMPONENT CONTAINER
#projects-sec {
  grid-column: 2/3;
  display: grid;
  grid-template-rows:
    auto auto $project-spacing-grid auto
    $project-spacing-grid auto $project-spacing-grid $blurb-spacing-grid;
}

#projects-sec ol {
  padding-left: 2rem;
}

//HEADING
#projects-head-cont {
  width: $middle-column-width;
  @include resp-width();
}

//PROJECT TEXT BOX
.project-cont {
  position: relative;
  display: grid;
  gap: $project-spacing-grid;
  align-items: center;
  @include resp-width();

  @media (max-width: $mobile-width) {
    grid-template-columns: 1fr;
  }

  &-pic-right {
    grid-template-columns: 60fr minmax(20rem, 40fr);

    @media (max-width: $mobile-width) {
      grid-template-columns: auto;
      grid-template-rows: auto auto;
      max-height: 100%;
    }
  }

  &-pic-left {
    grid-template-columns: minmax(20rem, 40fr) 60fr;
    @media (max-width: $mobile-width) {
      grid-template-columns: auto;
      grid-template-rows: auto auto;
      max-height: 100%;
    }
  }
}

.project-blurb-bkd {
  height: 100%;

  @media (max-width: $mobile-width) {
    width: 100%;
    align-self: flex-start;
  }

  &-color {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
  }

  &-left {
    order: 0;
  }

  &-right {
    order: 1;
    right: $bkd-width;

    @media (max-width: $mobile-width) {
      order: 0;
      right: auto;
    }
  }
}

.project-blurb-txt {
  align-self: flex-start;
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
  position: relative;
  color: white;
}

.upper-project-txt {
  & a {
    color: $blue;
  }
}

.project-skills {
  color: $blue;
  padding-top: 0.5rem;

  & ul {
    padding-left: 0;
    margin: 0;
  }

  & ul li {
    display: inline-block;
    padding-left: 1rem;

    &:first-child {
      padding-left: 0rem;
    }
  }
}

.project-links {
  display: flex;
  gap: 0.5rem;
  padding-top: 0.5rem;

  & a {
    display: flex;
    padding: 0;
  }
}

//MOBILE
.mobile-project-bkd {
  position: absolute;
  background-color: none;
  width: 100%;
  height: 100%;

  @media (max-width: $mobile-width) {
    background-color: black;
    @include resp-clamp(
      border-radius,
      $body-corner-padding-perc,
      $clamp-1,
      $clamp-2,
      $clamp-3
    );
  }
}

//PICTURE BOX
.pic-cont {
  z-index: 1;
  position: relative;
  aspect-ratio: 1/1;
  border-radius: 1rem;
  overflow: hidden;
  border: 0.4rem solid black;

  @media (max-width: $small-mobile-width) {
    max-height: 100%;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    border: 0.4rem solid black;
  }

  &-right {
    margin-left: auto;
  }

  &-left {
    margin-right: auto;
  }
}
