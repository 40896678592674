/* TODO:  
*/
@use 'src/Sass/global' as *;

// FITS CAROUSEL PIX
.project-img {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}
