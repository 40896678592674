/* TODO:  
*/
@use 'src/Sass/global' as *;

// COMPONENT CENTERED ON MIDDLE COLUMN OF MAIN LAYOUT GRID
// THEN ORGANIZED INTO VERTICAL GRID
#about-sec {
  grid-column: 2/3;
  grid-row: 2/3;
  display: grid;
  grid-template-rows: min-content min-content $blurb-spacing-grid min-content min-content $blurb-spacing-grid;
  @include resp-width();
}

//ABOUT ME SEC
#about-head-link {
  grid-row: 1/2;
  grid-column: 1/2;
}
#about-head {
  grid-row: 1/2;
  grid-column: 1/2;
}

#about-body-cont {
  grid-row: 2/3;
  grid-column: 1/2;
}

#about-body {
  font-weight: 500;
  p {
    padding-top: 0;
  }
  ul {
    column-count: 2;
  }
  h2 {
    margin-top: 1rem;
  }
}

#about-body-link {
  grid-column: 1/2;
  grid-row: 2/3;
}

//WHERE IVE WORKED SEC
#where-head-cont {
  grid-column: 1/2;
  grid-row: 4/5;
}

#where-body-cont {
  grid-column: 1/2;
  grid-row: 5/6;
}

#where-body {
  display: grid;
  grid-template-rows: auto 1fr;
  & ul {
    column-count: 1;
  }
}

#where-body-link {
  grid-column: 1/2;
  grid-row: 5/6;
}

.heading-links {
  display: grid;
  grid-template-columns: min-content 2ch min-content;
  & button h2 {
    position: relative;
    color: $yellow;
    z-index: 1;
  }

  button {
    position: relative;
    display: inline;
    padding: 0 1rem 0 1rem;
    margin-bottom: 0.5rem;
    border: none;
    border-radius: 0.5rem;
    background-color: rgba(255, 255, 255, 0.15);
    text-align: center;
    cursor: pointer;
  }
}
