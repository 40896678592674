/* TODO:  
*/
@use 'src/Sass/global' as *;

// COMPONENT CENTERED ON MIDDLE COLUMN OF MAIN LAYOUT GRID
// THEN ORGANIZED INTO VERTICAL GRID
#contact-sec {
  display: grid;
  grid-template-rows: min-content min-content $blurb-spacing-grid;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  grid-column: 2/3;

  @media (max-width: $mobile-width) {
    width: 100%;
  }
}

#contact-sec .blurb p {
  padding: 0;
}

.contact-head {
  position: relative;
}

.contact-cont {
  display: flex;
  justify-content: center;
}

.contact-bkd {
  text-align: center;
}

.contact-head-cont,
.contact-cont {
  @include resp-width();
}

#contact-sec .project-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
  padding-top: 0.5rem;
  color: $blue;

  & a {
    display: flex;
    padding: 0;
  }

  p {
    display: block;
    padding: 0;
    color: $blue;
  }

  &-button {
    display: flex;
    justify-content: center;
    color: initial;
    display: block;
    text-align: center;
    background-color: black;
    border: 0.15rem solid $blue;
    border-radius: 99rem;
    color: $blue;
    width: 5rem;
    height: min-content;
    padding: 0;

    &:hover {
      background-color: hsla(180.5, 97.5%, 53.1%, 25%);
    }
  }
}
