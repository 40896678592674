/* TODO: 
*/

@use 'src/Sass/global' as *;

/* reset css */
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100svh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-weight: 500;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* My formatting.*/
body,
a,
p {
  padding: 0;
  margin: 0;
  border: 0;
  font-family: 'Manrope', sans-serif;
}

a {
  color: black;
  text-decoration: none;
}

img {
  display: block;
}

h1 {
  color: $blue;
  font-weight: 900;
  font-size: 1.45rem;
}

h2 {
  color: $blue;
  font-weight: 700;
  font-size: 1.3rem;
}

h3 {
  color: $blue;
  font-weight: 500;
  font-size: 1.15rem;
}

/* utility classes */
.yellow-txt {
  color: $yellow;
}

.blue-txt {
  color: $blue;
}

.white-txt {
  color: white;
}

.center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

// *****************************BOXES AFTER TITLE
// CLAMP VALUES
$clamp-1: 8rem;
$clamp-2: 30vw;
$clamp-3: 9rem;

//HEAD PERCENTAGE ADJUSTMENTS
//ADJUST PERC FOR EVERYTHING OR VALUES IN PARENTHESES
//FOR IDIVIDUAL
$head-perc: 0.9;
$head-corner-padding-perc: (0.13 * $head-perc) + 0.02;
$head-font-perc: (0.16 * $head-perc) + 0.03;

//BODY PERCENTAGE ADJUSTMENTS
//ADJUST PERC FOR EVERYTHING OR VALUES IN PARENTHESES
//FOR IDIVIDUAL
$body-perc: 0.7;
$body-corner-padding-perc: (0.17 * $body-perc) + 0.02;
$body-font-perc: (0.17 * $body-perc) + 0.02;

.blurb {
  background-color: black;
  color: white;
  @include resp-clamp(
    border-radius,
    $body-corner-padding-perc,
    $clamp-1,
    $clamp-2,
    $clamp-3
  );
  @include resp-clamp(
    padding,
    $body-corner-padding-perc,
    $clamp-1,
    $clamp-2,
    $clamp-3
  );

  ul {
    list-style: disc;
    padding: 0 0 0 2rem;
  }

  p,
  h1,
  h2,
  h3 {
    padding: 0;
  }

  p {
    padding-top: 0.5rem;
  }
}

.head-right {
  margin-left: auto;
  @include resp-clamp(
    left,
    $head-corner-padding-perc,
    $clamp-1,
    $clamp-2,
    $clamp-3
  );
}

.head-left {
  margin-right: auto;
  @include resp-clamp(
    right,
    $head-corner-padding-perc,
    $clamp-1,
    $clamp-2,
    $clamp-3
  );
}

.head {
  position: relative;
  @include resp-clamp(
    top,
    calc($head-corner-padding-perc/2),
    $clamp-1,
    $clamp-2,
    $clamp-3
  );
  z-index: -1;
  width: fit-content;
  height: 100%;
  background-color: $yellow;
  @include resp-clamp(
    border-radius,
    $head-corner-padding-perc,
    $clamp-1,
    $clamp-2,
    $clamp-3
  );
  @include resp-clamp(
    padding,
    $head-corner-padding-perc,
    $clamp-1,
    $clamp-2,
    $clamp-3
  );
  @include resp-clamp(font-size, $head-font-perc, $clamp-1, $clamp-2, $clamp-3);
  font-weight: 700;
  line-height: 1;
}

/* INDIVIDUAL FORMATTING */
#root {
  //VARIABLES
  //SWIPER COLOR CHANGES
  --swiper-theme-color: #13fafc;
  --swiper-pagination-bullet-inactive-color: rgb(80, 80, 80);
  --swiper-pagination-bullet-inactive-opacity: 1;
  /* center main content */
  display: flex;
  justify-content: center;
  align-items: center;
}

#content {
  display: grid;
  grid-template-columns: 1fr $middle-column-width 1fr;
  overflow-x: hidden;
}
