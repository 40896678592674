/* TODO:  
*/
@use 'src/Sass/global' as *;

//CLICKING SWITCHES CLASSES BETWEEN CLICK AND NO CLICK
//CLICK EQUALS HAMBURGER MENU OPEN

//HAMBURGER ICON
$ham-spacing: 0.5rem;
$nav-spacing: 1rem;
//background clamp variables
$bkd-clamp-1: 5rem;
$bkd-clamp-2: 27vw;
$bkd-clamp-3: 12rem;
//bkd percentage size
$bkd-perc: 0.6;
$bkd-outline-perc: 0.1 * $bkd-perc;

header {
  grid-column: 1/4;
  grid-row: 1/2;
  width: 100vw;
}

nav {
  display: grid;
  position: relative;
  grid-template-columns: min-content 1fr min-content;
  padding: $nav-spacing;
  z-index: 1;

  @media (max-width: $mobile-width) {
    grid-template-columns: min-content 1fr;
  }
}

//HAMBURGER MENU
#ham-menu {
  display: none;

  @media (max-width: $mobile-width) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    z-index: 2;
  }
}

//NAVIGATION LINKS
.nav-links {
  z-index: 1;
  margin-top: auto;
  margin-bottom: auto;

  &-click {
    @media (max-width: $mobile-width) {
      color: $yellow;
      display: block !important;
    }
  }

  &-button {
    color: initial;
    margin: auto;
    display: block;
    text-align: center;
    background-color: black;
    border: 0.15rem solid $yellow;
    border-radius: 99rem;
    color: $yellow;
    width: 5rem;
    height: min-content;
    padding: 0;
  }

  @media (max-width: $mobile-width) {
    display: none;
  }
}

.nav-links-cont {
  display: grid;
  grid-template-columns: repeat(5, min-content);
  gap: 1rem;
  margin-left: auto;

  @media (max-width: $mobile-width) {
    grid-template-columns: min-content;
  }

  &-click {
    @media (max-width: $mobile-width) {
      display: flex !important;
      gap: 0;
      flex-direction: column;
      justify-content: flex-end;
      position: absolute;
      padding: 1rem;
      border-bottom-left-radius: 0.7rem;
      right: $nav-spacing;
      top: 0rem;
      width: 10rem;
      font-size: 1rem;
      right: 0;
      background-color: black;
      outline: $blue solid
        clamp(
          calc($bkd-clamp-1 * $bkd-outline-perc),
          calc($bkd-clamp-2 * $bkd-outline-perc),
          calc($bkd-clamp-3 * $bkd-outline-perc)
        );

      a {
        margin: $ham-spacing;
      }

      div {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        gap: $ham-spacing;
        padding: $ham-spacing;

        //github, linkedin links
        a {
          text-align: center;
          line-height: 2;
          width: fit-content;
          margin: auto;
          svg {
            fill: $yellow;
          }
        }
      }
    }
  }
}

// LOGO
.logo img {
  margin-right: auto;
}
