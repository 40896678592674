/* TODO:  
*/
@use 'src/Sass/global' as *;

// COMPONENT CENTERED ON MIDDLE COLUMN OF MAIN LAYOUT GRID
// THEN ORGANIZED INTO VERTICAL GRID

//the clamp numbers and percentages ensure everything is consistent in
//responsiveness and scales equally. Adjusting percentages increases
//size.

//font-size controls box size.

//USING PERCENTAGES ALLOWS FOR THE ABILITY TO GO BACK AND
//ADJUST VISUAL LOOK ON THE FLY

//title clamp variables
$title-clamp-1: 5rem;
$title-clamp-2: 27vw;
$title-clamp-3: 12rem;
//caption clamp variables
$caption-clamp-1: 6rem;
$caption-clamp-2: 30vw;
$caption-clamp-3: 9rem;
//title percentage size
$title-perc: 1;
$title-corner-padding-perc: (0.2 * $title-perc) + 0.02;
$title-font-size-perc: 1 * $title-perc;
$title-sub-font-size-perc: 0.26 * $title-perc;
$title-outline-perc: 0.1 * $title-perc;
//caption percentage size
$caption-perc: 1.3;
$caption-font-size-perc: 0.11 * $caption-perc;
$caption-padding-perc: 0.11 * $caption-perc;
$caption-outline-perc: 0.03 * $caption-perc;

#landing {
  grid-column: 1/4;
  display: grid;
  grid-template-columns: 1fr max(20rem, 70vw) 1fr;
  grid-template-rows:
    min-content minmax(3rem, 1fr) min-content minmax(3rem, 1fr)
    min-content minmax(3rem, 1fr);
  height: min(100vh, 65rem);
  position: relative;
}

//YELLOW TITLE SECTION *******************************************
#shooz-bkd-wrapper {
  position: relative;
  grid-column: 1/4;
  grid-row: 3/4;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  width: 100vw;
}

#side-shooz-bkd {
  position: absolute;
  grid-column: 1/3;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-color: rgb(0, 0, 0);
  @include resp-clamp(
    border-top-right-radius,
    $title-corner-padding-perc,
    $title-clamp-1,
    $title-clamp-2,
    $title-clamp-3
  );
  @include resp-clamp(
    border-bottom-right-radius,
    $title-corner-padding-perc,
    $title-clamp-1,
    $title-clamp-2,
    $title-clamp-3
  );
  box-shadow: 0 0 0
    clamp(
      $title-clamp-1 * $title-outline-perc,
      $title-clamp-2 * $title-outline-perc,
      $title-clamp-3 * $title-outline-perc
    )
    yellow;
}

#shooz-bkd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-column: 2/3;
  font-weight: 700;
  background-color: none;
  @include resp-clamp(
    padding-right,
    $title-corner-padding-perc,
    $title-clamp-1,
    $title-clamp-2,
    $title-clamp-3
  );
  @include resp-clamp(
    padding-left,
    $title-corner-padding-perc,
    $title-clamp-1,
    $title-clamp-2,
    $title-clamp-3
  );
  @include resp-clamp(
    border-top-right-radius,
    $title-corner-padding-perc,
    $title-clamp-1,
    $title-clamp-2,
    $title-clamp-3
  );
  @include resp-clamp(
    border-bottom-right-radius,
    $title-corner-padding-perc,
    $title-clamp-1,
    $title-clamp-2,
    $title-clamp-3
  );
}

/* TITLE TEXT */
/* padding and corner same variable for visual consistency */

#shooz-bkd p {
  line-height: 0.8;
}

#shooz-bkd p:first-child {
  line-height: 0.8;
  @include resp-clamp(
    font-size,
    $title-font-size-perc,
    $title-clamp-1,
    $title-clamp-2,
    $title-clamp-3
  );
  @include resp-clamp(
    padding-top,
    $title-corner-padding-perc,
    $title-clamp-1,
    $title-clamp-2,
    $title-clamp-3
  );
}

/* TITLE SUBTITLE TEXT */

#shooz-bkd p:nth-child(2) {
  line-height: 0.8;
  @include resp-clamp(
    font-size,
    $title-sub-font-size-perc,
    $title-clamp-1,
    $title-clamp-2,
    $title-clamp-3
  );
  @include resp-clamp(
    padding,
    $title-corner-padding-perc,
    $title-clamp-1,
    $title-clamp-2,
    $title-clamp-3
  );
}

/*CAPTION CSS****************************************************  */

#title-caption-wrapper {
  position: relative;
  grid-column: 1/4;
  grid-row: 5/6;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  width: 100vw;
}

#title-caption-bkd {
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  background-color: $black;
  position: relative;
  font-weight: 500;
  color: white;
  text-align: left;
  @include resp-clamp(
    font-size,
    $caption-font-size-perc,
    $caption-clamp-1,
    $caption-clamp-2,
    $caption-clamp-3
  );
  @include resp-clamp(
    padding,
    $caption-padding-perc,
    $caption-clamp-1,
    $caption-clamp-2,
    $caption-clamp-3
  );
  @include resp-clamp(
    border-top-left-radius,
    $caption-padding-perc,
    $caption-clamp-1,
    $caption-clamp-2,
    $caption-clamp-3
  );
  @include resp-clamp(
    border-bottom-left-radius,
    $caption-padding-perc,
    $caption-clamp-1,
    $caption-clamp-2,
    $caption-clamp-3
  );
  p {
    line-height: 1.5;
  }
}

#title-caption-side-bkd {
  position: absolute;
  grid-column: 2/4;
  grid-row: 1/2;
  height: 100%;
  width: 100%;
  background-color: $black;
  box-shadow: 0 0 0
    clamp(
      calc($caption-clamp-1 * $caption-outline-perc),
      calc($caption-clamp-2 * $caption-outline-perc),
      calc($caption-clamp-3 * $caption-outline-perc)
    )
    $blue;
  @include resp-clamp(
    border-top-left-radius,
    $caption-padding-perc,
    $caption-clamp-1,
    $caption-clamp-2,
    $caption-clamp-3
  );
  @include resp-clamp(
    border-bottom-left-radius,
    $caption-padding-perc,
    $caption-clamp-1,
    $caption-clamp-2,
    $caption-clamp-3
  );
}

.hover-blue:hover {
  color: $blue;
}
