/* TODO:  
*/
@use 'src/Sass/global' as *;

//ALLOWS SIZE ADJUSTMENTS
$perc-width: 0.5;

//HAMBURGER ICON
#nav-icon1 {
  width: 60px * $perc-width;
  height: 45px * $perc-width;
  position: relative;
  margin: auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span {
  display: block;
  position: absolute;
  height: 9px * $perc-width;
  width: 100%;
  background: $black;
  border-radius: 9px * $perc-width;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.1s ease-in-out;
  -moz-transition: 0.1s ease-in-out;
  -o-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 0px * $perc-width;
}

#nav-icon1 span:nth-child(2) {
  top: 18px * $perc-width;
  -webkit-transition: 0.1s ease-in-out;
  -moz-transition: 0.1s ease-in-out;
  -o-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
}

#nav-icon1 span:nth-child(3) {
  top: 36px * $perc-width;
}

//CLICKING ANIMATION
#nav-icon1.open span {
  background-color: $blue;
}

#nav-icon1.open span:nth-child(1) {
  top: 18px * $perc-width;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#nav-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -60px * $perc-width;
}

#nav-icon1.open span:nth-child(3) {
  top: 18px * $perc-width;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
