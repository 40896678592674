$mobile-width: 45rem;
$small-mobile-width: 30rem;
$yellow: #f4ff0a;
$blue: #13fafc;
$black: #000000;
$round-corner: clamp(0.5rem, 5vw, 0.9rem);
$nav-height: 4rem;
$middle-column-width: min(80ch, 92vw);
$mobile-middle-column-width: min(30ch, 92vw);
$blurb-spacing-grid: minmax(6rem, auto);

//MIXINS
//USED MOSTLY FOR ROUNDED CORNERS AND SPACING OF THE PROJECT.
//CLAMP NUMBERS ARE USED IN CLAMPS AND THE $PER IS TO ADJUST
//PROPORTIONS ON THE FLY
@mixin resp-clamp($prop, $per, $clamp-1, $clamp-2, $clamp-3) {
  #{$prop}: clamp($clamp-1 * $per, $clamp-2 * $per, $clamp-3 * $per);
}
//FOR THE MOBILE WIDTH OF CONT SPANNING THE ENTIRE MIDDLE
@mixin resp-width() {
  @media (max-width: $mobile-width) {
    width: min(40ch, 92vw);
    margin-left: auto;
    margin-right: auto;
  }
}
